import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from '@src/ui/components/Loading/Loading';
import ProgressBar from '@src/ui/components/ProgressBar/ProgressBar';
import { ServiceRequestFormState } from '@src/ui/apps/ServiceRequest/serviceRequestFormReducer';
import { scrollerFunction, scrollIntoForm } from '@src/ui/apps/ServiceRequest/Steps/utils';
import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import { usePhotoUploadMethods } from '@src/ui/apps/ServiceRequest/hooks/usePhotoUpload';
import FormWizard from '@src/ui/apps/ServiceRequest/FormWizard';
import JobTypeStep from '@src/ui/apps/ServiceRequest/Steps/JobTypeStep/JobTypeStep';
import LocationStep from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';
import {
  MiddleNextStepFormControls,
  MiddleStepFormControls,
} from '@src/ui/apps/ServiceRequest/MiddleStepFormControls';
import { ChangeJobTypeLink } from '@src/ui/apps/ServiceRequest/ChangeJobTypeLink';
import QuestionStep from '@src/ui/apps/ServiceRequest/Steps/QuestionStep/QuestionStep';
import SharePrivateServiceRequestStep from '@src/ui/apps/ServiceRequest/Steps/SharePrivateServiceRequestStep/SharePrivateServiceRequestStep';
import HOExperiencePreferenceStep from '@src/ui/apps/ServiceRequest/Steps/HOExperiencePreferenceStep/HOExperiencePreferenceStep';
import DescriptionStep from '@src/ui/apps/ServiceRequest/Steps/DescriptionStep/DescriptionStep';
import { CustomerContactStep } from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/CustomerContactStep';
import Alert, { AlertTypes } from '@src/ui/components/Alert/Alert';
import FreeAndWithoutObligation from '@src/ui/apps/ServiceRequest/FreeAndWithoutObligation';
import FinalStepFormControls from '@src/ui/apps/ServiceRequest/FinalStepFormControls';
import CommercialPhone from '@src/ui/apps/ServiceRequest/CommercialPhone';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { sessionIdCreated } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import { ExtraConfiguration } from '@src/core/ApplicationConfiguration';
import ConfidenceWarrantyBrandIndicator from '@src/ui/apps/ServiceRequest/ConfidenceWarrantyBrandIndicator';
import classNames from 'classnames';
import NewDesignProgressBar from '@src/ui/components/NewDesignProgressBar/NewDesignProgressBar';

export type ServiceRequestFormRendererProps = usePhotoUploadMethods & {
  state: ServiceRequestFormState;
  goBackHandler: () => void;
  goFirstHandler: () => void;
  businessId?: string;
  campaignId?: string;
  userSource?: string;
  hideSharePrivateServiceRequestStep?: boolean;
  showCommercialPhone?: boolean;
  enableHOExperiencePreference?: boolean;
  extraConfiguration?: ExtraConfiguration;
  scrollFunction?: scrollerFunction;
  header?: React.ReactElement;
};

function ServiceRequestFormRenderer({
  state,
  goBackHandler,
  goFirstHandler,
  uploads,
  onFileAdded,
  removeFile,
  businessId,
  hideSharePrivateServiceRequestStep,
  showCommercialPhone,
  enableHOExperiencePreference,
  extraConfiguration,
  scrollFunction = extraConfiguration?.isDirectory || extraConfiguration?.disableAutoScroll
    ? undefined
    : scrollIntoForm,
  header,
}: ServiceRequestFormRendererProps): React.ReactElement {
  const orderHOExperiencePreference = useRef<string>();
  const previousStepRef = useRef(state.currentStep);
  const [isPopStateEvent, setIsPopStateEvent] = useState(false);

  eventBusSingleton.addListener(sessionIdCreated.eventName, (event: sessionIdCreated) => {
    orderHOExperiencePreference.current =
      parseInt(event.sessionId.substr(event.sessionId.length - 1)) % 2 === 0
        ? 'leadgen up'
        : 'leadgen down';
  });

  const showProgressBar = !extraConfiguration?.quickFlow || extraConfiguration?.showProgressBar;
  const showDesignVariant = !!extraConfiguration?.showDesignVariant;

  const showLoader =
    (extraConfiguration && extraConfiguration?.quickFlow && state.isSendingRequest) ||
    state.isLoadingSlug ||
    state.categories.length === 0 ||
    (state.needsToLoadQuestions && state.currentStep > 2);

  const formRef = useRef<HTMLDivElement>(null);
  const jobTypeBackButtonRef = useRef<HTMLButtonElement>(null);
  const handleJobTypeBackButtonClick = () => {
    if (jobTypeBackButtonRef.current) {
      jobTypeBackButtonRef.current.click();
    }
  };

  const isInitialStep = useRef(true);
  useEffect(() => {
    if (isInitialStep.current === true) {
      isInitialStep.current = false;
      return;
    }

    if (previousStepRef.current < state.currentStep && !isPopStateEvent) {
      window.history.pushState({ step: state.currentStep }, '');
    } else {
      window.history.replaceState({ step: state.currentStep }, '');
    }
    previousStepRef.current = state.currentStep;
    setIsPopStateEvent(false);

    if (scrollFunction) {
      scrollFunction(formRef);
    }
  }, [state.currentStep]);

  useEffect(() => {
    window.history.pushState({ step: state.currentStep }, '');
    const handlePopState = (event: PopStateEvent) => {
      setIsPopStateEvent(true);
      const step = event.state?.step;

      if (!step) {
        window.history.back();
        return;
      }

      if (step < previousStepRef.current) {
        goBackHandler();
      } else if (step > previousStepRef.current) {
        document.querySelector<HTMLButtonElement>('[data-testid="nextButton"]')?.click();
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    state.errors.global = '';
  }, [goBackHandler]);

  const showFreeAndWithoutObligation =
    !extraConfiguration || (!extraConfiguration.isDirectory && !extraConfiguration.quickFlow);

  const showConfidenceWarranty =
    !extraConfiguration || (!extraConfiguration.isDirectory && !extraConfiguration.quickFlow);

  return (
    <div
      ref={formRef}
      className={classNames(styles['serviceRequestForm'], {
        [styles['serviceRequestForm--showDesignVariant']]: showDesignVariant,
      })}
    >
      {showLoader && <Loading />}
      {!showLoader && (
        <>
          {showProgressBar &&
            (showDesignVariant ? (
              <NewDesignProgressBar
                progress={state.currentStep / state.totalSteps}
                showDesignVariant={showDesignVariant}
                currentStep={state.currentStep}
                totalSteps={state.totalSteps}
                goBack={goBackHandler}
                showSharePrivateServiceStep={!!businessId && !hideSharePrivateServiceRequestStep}
                onJobTypeStepBackButtonClick={handleJobTypeBackButtonClick}
              />
            ) : (
              <ProgressBar
                progress={state.currentStep / state.totalSteps}
                className={styles.serviceRequestForm__progressBar}
                showDesignVariant={showDesignVariant}
              />
            ))}

          <div
            className={classNames(styles.serviceRequestForm__header, {
              [styles['serviceRequestForm__header--showDesignVariant']]: showDesignVariant,
            })}
            data-testid="serviceRequestFormHeader"
          >
            {header}
          </div>

          <FormWizard step={state.currentStep} showDesignVariant={showDesignVariant}>
            <JobTypeStep
              ref={jobTypeBackButtonRef}
              categoriesTree={new CategoryGroupTree(state.categories)}
              subCategories={extraConfiguration?.subSubCategories ?? []}
              tagLabel={extraConfiguration?.tagLabel ?? ''}
              changeJobTypeLink={<ChangeJobTypeLink onClick={goFirstHandler} />}
              showSelectedJobType={!extraConfiguration?.quickFlow}
              showHeader={!extraConfiguration?.quickFlow}
              showBackButton={!!extraConfiguration?.quickFlow}
              showDesignVariant={showDesignVariant}
            />
            <LocationStep
              formControls={
                extraConfiguration?.isDirectory && extraConfiguration?.skipCategoryStep ? (
                  <MiddleNextStepFormControls showDesignVariant={showDesignVariant} />
                ) : (
                  <MiddleStepFormControls
                    goBack={goBackHandler}
                    showDesignVariant={showDesignVariant}
                  />
                )
              }
              changeJobTypeLink={
                extraConfiguration?.isDirectory ? undefined : (
                  <ChangeJobTypeLink onClick={goFirstHandler} />
                )
              }
              showDesignVariant={showDesignVariant}
            />

            {enableHOExperiencePreference && (
              <HOExperiencePreferenceStep
                order={orderHOExperiencePreference.current}
                formControls={
                  <MiddleStepFormControls
                    goBack={goBackHandler}
                    showDesignVariant={showDesignVariant}
                  />
                }
              />
            )}
            {state.questions.map((question, index) => (
              <QuestionStep
                key={question.id}
                formControls={
                  <MiddleStepFormControls
                    goBack={goBackHandler}
                    showDesignVariant={showDesignVariant}
                  />
                }
                question={question}
                changeJobTypeLink={
                  extraConfiguration?.isDirectory ? undefined : (
                    <ChangeJobTypeLink onClick={goFirstHandler} />
                  )
                }
                showDesignVariant={showDesignVariant}
              />
            ))}
            {businessId && !hideSharePrivateServiceRequestStep && (
              <SharePrivateServiceRequestStep
                showDesignVariant={showDesignVariant}
                formControls={
                  <MiddleStepFormControls
                    goBack={goBackHandler}
                    showDesignVariant={showDesignVariant}
                  />
                }
              />
            )}
            <DescriptionStep
              uploads={uploads}
              formControls={
                <MiddleStepFormControls
                  goBack={goBackHandler}
                  showDesignVariant={showDesignVariant}
                />
              }
              onFilesAdded={onFileAdded}
              removeFile={removeFile}
              showDesignVariant={showDesignVariant}
            />
            <CustomerContactStep
              errors={state.errors}
              uploadingPhotosCount={uploads.uploading.length}
              showDesignVariant={showDesignVariant}
              formControls={
                <FinalStepFormControls
                  goBack={goBackHandler}
                  isSubmitting={state.isSendingRequest}
                  datavwo="goBackButton"
                  showDesignVariant={showDesignVariant}
                />
              }
            />
          </FormWizard>

          {state.errors.unauthorized && (
            <Alert variant={AlertTypes.ERROR}>
              <span>{state.errors.unauthorized}</span>
            </Alert>
          )}

          {state.errors.global && (
            <Alert variant={AlertTypes.ERROR}>
              <span>
                <FormattedMessage
                  id="serviceRequestForm.globalError"
                  defaultMessage="Lo sentimos, ha ocurrido un error inesperado. Por favor, vuelve a intentarlo"
                  description="Mensaje de error que se muestra al usuario cuando no se ha podido crear la solicitud de presupuesto"
                />
              </span>
            </Alert>
          )}

          {!extraConfiguration?.showDesignVariant
            ? showFreeAndWithoutObligation && <FreeAndWithoutObligation />
            : ''}

          {!extraConfiguration?.showDesignVariant
            ? showConfidenceWarranty && <ConfidenceWarrantyBrandIndicator />
            : ''}

          {showCommercialPhone && <CommercialPhone />}
        </>
      )}
    </div>
  );
}

export default ServiceRequestFormRenderer;
