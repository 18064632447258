import { EventBus } from '@src/core/infrastructure/Events/EventBus';
import {
  CategorySelected,
  ContactDataStepRendered,
  DescriptionStepRendered,
  ImageUploadEnded,
  ImageUploadStarted,
  JobTypeSelected,
  JobTypeStepRendered,
  LocationStepRendered,
  LocationWithParentSubmitted,
  PostalCodeLocationSubmitted,
  HOExperiencePreferenceStepRendered,
  HOExperiencePreferenceClickedDirectory,
  HOExperiencePreferenceClickedReject,
  QuestionsLoaded,
  QuestionStepRendered,
  ServiceRequestType,
  ServiceRequestFormSubmitted,
  SharePrivateServiceRequestClickedReject,
  SharePrivateServiceRequestClickedShare,
  SharePrivateServiceRequestStepRendered,
  SearchServiceRequestCategory,
  ContactStepErrors,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import {
  ForwardServiceRequestClickedAccept,
  ForwardServiceRequestClickedViewDetails,
  ForwardServiceRequestClickedReject,
  ForwardServiceRequestRendered,
} from '@src/ui/apps/ForwardServiceRequest/ForwardServiceRequestEvents';
import Question from '@src/core/domain/Questions/Question';
import { ExtraConfiguration, FormConfiguration } from '@src/core/ApplicationConfiguration';
import { AmplitudeClient } from '../../AmplitudeQueuedClient';
import SearchStateFactory from '@src/core/useCases/State/SearchStateFactory';
import GetCategoryLegacyIdFactory from '@src/core/useCases/Category/GetCategoryLegacyIdFactory';

class AmplitudeServiceRequestFormEventListener {
  private _client: AmplitudeClient;
  private _country: string;
  private _accumulatedData: Record<string, unknown>;
  private _questions: Question[];
  private hasClickedAccept: string;
  constructor(
    client: AmplitudeClient,
    country: string,
    formConfiguration: FormConfiguration,
    initialAccumulatedData: Record<string, string> = {},
    hasClickedAccept: string | null
  ) {
    this._client = client;
    this._country = country;
    this._accumulatedData = {
      form: formConfiguration.name,
      source_start_page: formConfiguration.sourceStartPage,
      source_end_page: formConfiguration.sourceEndPage,
      path: window.location.pathname + window.location.search,
      ...initialAccumulatedData,
    };
    this._questions = [];
    this.hasClickedAccept = hasClickedAccept ? hasClickedAccept : 'no private';

    if (formConfiguration.extraConfiguration) {
      this.setExtraData(formConfiguration.extraConfiguration);
    }
  }

  register(dispatcher: EventBus): void {
    if (!this._client) {
      console.warn('Amplitude client not found, not registering amplitude event listeners');
      return;
    }

    this.initializeAmplitudeUserProperties();

    dispatcher.addListener(
      ForwardServiceRequestRendered.eventName,
      this.forwardServiceRequestRendered.bind(this)
    );
    dispatcher.addListener(
      ForwardServiceRequestClickedAccept.eventName,
      this.forwardServiceRequestClickedAccept.bind(this)
    );
    dispatcher.addListener(
      ForwardServiceRequestClickedReject.eventName,
      this.forwardServiceRequestClickedReject.bind(this)
    );
    dispatcher.addListener(
      ForwardServiceRequestClickedViewDetails.eventName,
      this.forwardServiceRequestClickedViewDetails.bind(this)
    );

    dispatcher.addListener(JobTypeStepRendered.eventName, this.categoryStepViewed.bind(this));
    dispatcher.addListener(CategorySelected.eventName, this.categorySelected.bind(this));
    dispatcher.addListener(JobTypeSelected.eventName, this.jobTypeSelected.bind(this));
    dispatcher.addListener(LocationStepRendered.eventName, this.locationStepViewed.bind(this));

    dispatcher.addListener(
      PostalCodeLocationSubmitted.eventName,
      this.postalCodeLocationSubmitted.bind(this)
    );

    dispatcher.addListener(
      LocationWithParentSubmitted.eventName,
      this.locationWithParentSubmitted.bind(this)
    );

    dispatcher.addListener(
      HOExperiencePreferenceStepRendered.eventName,
      this.HOExperiencePreferenceStepRendered.bind(this)
    );

    dispatcher.addListener(
      HOExperiencePreferenceClickedDirectory.eventName,
      this.HOExperiencePreferenceClickedDirectory.bind(this)
    );

    dispatcher.addListener(
      HOExperiencePreferenceClickedReject.eventName,
      this.HOExperiencePreferenceClickedReject.bind(this)
    );

    dispatcher.addListener(QuestionsLoaded.eventName, this.questionsLoaded.bind(this));
    dispatcher.addListener(QuestionStepRendered.eventName, this.questionStepViewed.bind(this));
    dispatcher.addListener(
      SharePrivateServiceRequestStepRendered.eventName,
      this.sharePrivateServiceRequestStepViewed.bind(this)
    );
    dispatcher.addListener(
      SharePrivateServiceRequestClickedShare.eventName,
      this.sharePrivateServiceRequestClickedShare.bind(this)
    );
    dispatcher.addListener(
      SharePrivateServiceRequestClickedReject.eventName,
      this.sharePrivateServiceRequestClickedReject.bind(this)
    );
    dispatcher.addListener(
      DescriptionStepRendered.eventName,
      this.descriptionStepViewed.bind(this)
    );
    dispatcher.addListener(ImageUploadStarted.eventName, this.imageUploadStarted.bind(this));
    dispatcher.addListener(ImageUploadEnded.eventName, this.imageUploadEnded.bind(this));
    dispatcher.addListener(
      ContactDataStepRendered.eventName,
      this.contactDataStepViewed.bind(this)
    );
    dispatcher.addListener(
      ServiceRequestFormSubmitted.eventName,
      this.serviceRequestSubmitted.bind(this)
    );
    dispatcher.addListener(
      SearchServiceRequestCategory.eventName,
      this.searchServiceRequestCategory.bind(this)
    );
    dispatcher.addListener(ContactStepErrors.eventName, this.contactStepErrors.bind(this));
    dispatcher.addListener(ServiceRequestType.eventName, this.serviceRequestType.bind(this));
  }

  private initializeAmplitudeUserProperties(): void {
    const properties = {
      country: this._country,
    };

    this._client.setUserProperties(properties);
  }

  private forwardServiceRequestRendered(event: ForwardServiceRequestRendered): void {
    this.logEventWithAccumulatedData('view step forward service request');
  }

  private forwardServiceRequestClickedAccept(event: ForwardServiceRequestClickedAccept): void {
    this.logEventWithAccumulatedData('click step forward service request', {
      selection: 'forward',
    });
  }

  private forwardServiceRequestClickedReject(event: ForwardServiceRequestClickedReject): void {
    this.logEventWithAccumulatedData('click step forward service request', {
      selection: 'reject',
    });
  }

  private forwardServiceRequestClickedViewDetails(
    event: ForwardServiceRequestClickedViewDetails
  ): void {
    this.logEventWithAccumulatedData('click step forward service request', {
      selection: 'details',
    });
  }

  private categoryStepViewed(event: JobTypeStepRendered): void {
    this.logEventWithAccumulatedData('view step subcategory');
  }

  private categorySelected(event: CategorySelected): void {
    this.logEventWithAccumulatedData('view step subsubcategory', {
      'subcategory id': event.category.id,
      'subcategory label': event.category.name,
    });
  }

  private contactStepErrors(event: ContactStepErrors): void {
    delete this._accumulatedData['subcategory id'];
    this.logEvent('contact details customer error', {
      'errors ': event.errors,
    });
  }
  private jobTypeSelected(event: JobTypeSelected): void {
    this._accumulatedData['subcategory id'] = event.category.id;
    this._accumulatedData['subcategory label'] = event.category.name;
    this._accumulatedData['subsubcategory id'] = event.jobType.id;
    this._accumulatedData['subsubcategory label'] = event.jobType.name;
  }

  private locationStepViewed(event: LocationStepRendered): void {
    if (event.jobTypeId != null) {
      this._accumulatedData['subsubcategory id'] = event.jobTypeId;
    }
    if (this._country === 'es' && event.postalCode != null) {
      this._accumulatedData['postal code'] = event.postalCode;
      this.stateWithPostalCodeSubmitted(event.postalCode).then(() => {});
    }
    this.logEventWithAccumulatedData('view step location');
  }

  private postalCodeLocationSubmitted(event: PostalCodeLocationSubmitted): void {
    this._accumulatedData['postal code'] = event.postalCode;
    delete this._accumulatedData['state label'];
    if (this._country === 'es') {
      this.stateWithPostalCodeSubmitted(event.postalCode).then(() => {});
    } else {
      delete this._accumulatedData['state id'];
    }
  }

  private locationWithParentSubmitted(event: LocationWithParentSubmitted): void {
    this._accumulatedData['state id'] = event.firstLevelLocation.id;
    this._accumulatedData['state label'] = event.firstLevelLocation.name;
    delete this._accumulatedData['postal code'];
  }

  private HOExperiencePreferenceStepRendered(event: HOExperiencePreferenceStepRendered): void {
    this.logEventWithAccumulatedData('view step preference find pro', {
      'preference option position': event.order,
    });
  }

  private HOExperiencePreferenceClickedDirectory(
    event: HOExperiencePreferenceClickedDirectory
  ): void {
    this.logEventWithAccumulatedData('click preference directory', {});
  }

  private HOExperiencePreferenceClickedReject(event: HOExperiencePreferenceClickedReject): void {
    this.logEventWithAccumulatedData('click preference leadgen', {});
  }

  private questionsLoaded(event: QuestionsLoaded): void {
    this._questions = event.questions;
  }

  private questionStepViewed(event: QuestionStepRendered): void {
    this.logEventWithAccumulatedData('view step dynamic questions', {
      'max questions': this._questions.length,
      'question index': this.positionOfQuestionStepForQuestionId(event.question.id),
      'question id': event.question.id,
      'question label': event.question.question,
    });
  }

  private sharePrivateServiceRequestStepViewed(
    event: SharePrivateServiceRequestStepRendered
  ): void {
    this.logEventWithAccumulatedData('view step is private');
  }

  private sharePrivateServiceRequestClickedShare(
    event: SharePrivateServiceRequestClickedShare
  ): void {
    this.hasClickedAccept = 'shared private';
    this.logEventWithAccumulatedData('click step is private', {
      selection: 'accept',
    });
  }

  private sharePrivateServiceRequestClickedReject(
    event: SharePrivateServiceRequestClickedReject
  ): void {
    this.hasClickedAccept = 'private';
    this.logEventWithAccumulatedData('click step is private', {
      selection: 'reject',
    });
  }

  private descriptionStepViewed(event: DescriptionStepRendered): void {
    this.logEventWithAccumulatedData('view step description');
  }

  private imageUploadStarted(event: ImageUploadStarted): void {
    this.logEvent('upload image started', {
      'file size': event.fileSizeInBytes,
    });
  }

  private imageUploadEnded(event: ImageUploadEnded): void {
    this.logEvent('upload image ended', {
      'file size': event.fileSizeInBytes,
    });
  }

  private contactDataStepViewed(event: ContactDataStepRendered): void {
    this.logEventWithAccumulatedData('view step contact details customer', {});
  }

  private serviceRequestSubmitted(event: ServiceRequestFormSubmitted): void {
    this.logEventWithAccumulatedData('service request submitted', {
      'service request type': this.hasClickedAccept,
    });
  }

  private searchServiceRequestCategory(event: SearchServiceRequestCategory): void {
    delete this._accumulatedData['source_end_page'];
    this.logEventWithAccumulatedData('search service request category', {
      'timeout search service request': event.timeoutSearch,
      'search service request term': event.searchQuery,
    });
  }

  private serviceRequestType(event: ServiceRequestType): void {
    if (event.remove) {
      delete this._accumulatedData['expected request type'];
    } else {
      this._accumulatedData['expected request type'] = event.serviceRequestType;
    }
  }

  private async logEventWithAccumulatedData(
    name: string,
    data: Record<string, unknown> = {}
  ): Promise<void> {
    const formattedData = await this.formatData({
      ...this._accumulatedData,
      ...data,
    });
    this.logEvent(name, formattedData);
  }

  public async formatData(data: Record<string, unknown> = {}): Promise<Record<string, unknown>> {
    if ('subcategory id' in data) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      data['subcategory id'] = await this.getLegacyIdOfCategory(<string>data['subcategory id']);
    }

    if ('subsubcategory id' in data) {
      data['subsubcategory id'] = await this.getLegacyIdOfCategory(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        <string>data['subsubcategory id']
      );
    }
    return data;
  }

  public logEvent(name: string, data: Record<string, unknown>): void {
    this._client.logEvent(name, data);
  }

  private positionOfQuestionStepForQuestionId(questionId: string): number {
    return this._questions.findIndex((question) => question.id === questionId) + 1;
  }

  private async stateWithPostalCodeSubmitted(postalCode: string): Promise<void> {
    try {
      const stateSearch = await SearchStateFactory.create().execute(postalCode);
      this._accumulatedData['state id'] = stateSearch.id.toString();
      this._accumulatedData['state label'] = stateSearch.name.toString();
    } catch (e) {
      delete this._accumulatedData['state id'];
    }
  }

  private async getLegacyIdOfCategory(categoryId: string): Promise<string> {
    if (categoryId === '') return '';
    try {
      const legacyId = await GetCategoryLegacyIdFactory.create().execute(categoryId);

      return legacyId.toString();
    } catch (e) {
      return categoryId;
    }
  }

  private setExtraData(extraConfiguration: ExtraConfiguration): void {
    if (extraConfiguration.trackSubcategoryId) {
      this._accumulatedData['subcategory id'] = extraConfiguration.trackSubcategoryId;
      this._accumulatedData['subcategory label'] = extraConfiguration.trackSubcategoryLabel;
    }

    if (extraConfiguration.trackSubsubcategoryId) {
      this._accumulatedData['subsubcategory id'] = extraConfiguration.trackSubsubcategoryId;
      this._accumulatedData['subsubcategory label'] = extraConfiguration.trackSubsubcategoryLabel;
    }
  }
}

export default AmplitudeServiceRequestFormEventListener;
